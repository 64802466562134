

.table thead tr {
    background-color:#2F353A;
    color: white;
}

.card-header {
    background-color: #2F353A !important;
    color: white;
}

.btn-primary {
    color: #fff;
    background-color: #2F353A !important;
    border-color: #2F353A !important;
}

.btn-secondary{
    color: #fff !important;
    background-color: #2F353A !important;
    border-color: #2F353A !important;
}

.pl-20 {
    padding-left: 20px;
}
.font-crash {
    font-size: 18px;
    font-weight: 600;
}